<template>
  <div style="padding:12px;">
    <div :style="backCss" class="text-list-top" style="padding-bottom:10px;">
      <span class="text-list-radio">
        <span
          @click="searchType = 'search'"
          :class="searchType == 'search' ? 'text-list-radio-selected' : 'text-list-radio1'"
        >
          <span :style="searchType == 'search' ? 'border:none' : ''">查找</span>
        </span>
        <span
          @click="searchType = 'change'"
          v-if="checkPermission('tranlate:replace')"
          :class="searchType == 'change' ? 'text-list-radio-selected' : 'text-list-radio2'"
        >
          <span :style="searchType == 'revisionText' ? 'border:none' : ''">替换</span>
        </span>
      </span>
    </div>
    <template v-if="searchType == 'search'">
      <div style="display:flex;padding-top:10px;">
        <div style="width:100%">
          <a-input-group compact style="display:flex;">
            <a-select v-model="type">
              <a-select-option :value="0">
                原文
              </a-select-option>
              <a-select-option :value="1">
                译文
              </a-select-option>
            </a-select>
            <a-input-search v-model="text" @search="search(0)" style="width: 95%" placeholder="请输入" />
          </a-input-group>
        </div>
        <!--         <div style="width:10%;line-height:32px;">
          <span v-if="!searchList.length">无结果</span>
          <span v-else>{{ showSeachIndex + 1 }}/{{ searchList.length }}</span>
        </div> -->
      </div>
      <div style="display:flex;padding-top:15px;display:flex;justify-content: space-between;">
        <div>
          <a-button size="small" style="font-size:12px;" @click="clear">重置</a-button>
        </div>
        <div>
          <a-button size="small" @click="search(1)" style="font-size:12px;">查找所有章节</a-button>
          <a-button size="small" @click="search(0)" style="margin-left:15px;font-size:12px;">查找当前章节</a-button>
        </div>
      </div>
      <div
        class="text-list-bottom"
        id='search-modal'
        :style="
          buttonType
            ? `padding-top:25px;${allHeight ? 'max-height:' + (bottomHeight - 255) + 'px !important' : ''}`
            : `padding-top:10px;${allHeight ? 'max-height:' + (bottomHeight - 240) + 'px !important' : ''}`
        "
      >
        <div v-if="searchList.length" class="open-all-annotation-list" :style="buttonType ? 'top:25px' : ''">
          <a
            v-if="
              textDefaultKey.length !=
                searchList
                  .map(c => c.chapter.length)
                  .reduce((prev, cur) => {
                    return prev + cur
                  })
            "
            @click.stop="openList"
            >全展开/收起</a
          >
          <a v-else @click.stop="closeList">全展开/收起</a>
        </div>
        <div v-for="c in searchList" :key="c.chapterId">
          <div v-if="buttonType" style="color:rgba(0, 0, 0, 0.85);padding-bottom:10px;">
            <span style="font-weight:bold">第{{ c.chapterOrder - 0 }}话</span>
            <span style="margin-left:20px">{{ `共${c.num}处` }}</span>
          </div>
          <a-collapse v-if="c.chapter.length" @change="textDefaultKeyChange" v-model="textDefaultKey" :bordered="false">
            <template #expandIcon="props">
              <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
            </template>
            <a-collapse-panel
              v-for="(page, pageIdx) in c.chapter"
              :key="page.pageId + '-' + c.chapterId"
              :style="customStyle"
            >
              <div slot="header">
                <span>{{ `第${page.sequenceNo}页` }}</span>
                <span style="margin-left:20px">{{ `共${page.textAreas.length}处` }}</span>
              </div>
              <div
                :class="
                  selectTextId === item.id
                    ? 'local-content-list-show local-content-list-show-selected'
                    : 'local-content-list-show'
                "
                v-for="item in page.textAreas"
                :key="item.id"
                :id="`search-${item.id}`"
              >
                <div class="local-content-list-num">
                  <div>{{ item.sequenceNo }}</div>
                </div>
                <div class="local-content-list">
                  <span v-if="type">{{ item.auditText || item.revisionText || item.translateText }}</span>
                  <span v-else>{{ item.originalText }}</span>
                  <a @click="toText(page, item, page.sequenceNo, c)" style="min-width:30px;">跳转</a>
                </div>
              </div>
            </a-collapse-panel>
          </a-collapse>
        </div>
      </div>
    </template>
    <template v-else>
      <div>
        <div style="display:flex;padding-top:15px;justify-content: space-between;">
          <div style="width:19%;line-height:32px;text-align:right">查找</div>
          <a-input v-model="searchValue" style="width:75%" />
        </div>
        <div style="display:flex;padding-top:15px;justify-content: space-between;">
          <div style="width:19%;line-height:32px;text-align:right">替换为</div>
          <a-input v-model="changeValue" style="width:75%" />
        </div>
        <div style="display:flex;padding-top:15px;padding-bottom:15px;justify-content: end;">
          <div>
            <a-button size="small" style="font-size:12px;" @click="clear">重置</a-button>
            <a-button size="small" style="margin-left:15px;font-size:12px;" @click="searchChange">查找</a-button>
          </div>
        </div>
      </div>
      <div
        class="text-list-bottom"
        :style="
          allHeight
            ? `border-top:1px solid rgba(0,0,0,0.1);position:relative;max-height:${bottomHeight - 310}px !important`
            : 'border-top:1px solid rgba(0,0,0,0.1);position:relative'
        "
      >
        <div v-if="changeList.length" class="open-all-annotation-list" style="top:12px">
          <a v-if="textChangeDefaultKey.length != changeList.length" @click.stop="openChangeList">全展开/收起</a>
          <a v-else @click.stop="closeChangeList">全展开/收起</a>
        </div>
        <a-collapse v-if="changeList.length" v-model="textChangeDefaultKey" :bordered="false">
          <template #expandIcon="props">
            <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
          </template>
          <a-collapse-panel v-for="page in changeList" :key="page.pageId + ''" :style="customStyle">
            <div slot="header">
              <span>{{ `第${page.order + 1}页` }}</span>
              <span style="margin-left:20px">{{ `共${page.textAreas.length}处` }}</span>
            </div>
            <div
              :class="
                selectTextChangeId === item.id
                  ? 'local-content-list-show local-content-list-show-selected'
                  : 'local-content-list-show'
              "
              v-for="item in page.textAreas"
              :key="item.id"
            >
              <div class="local-content-list-num">
                <div>{{ item.sequenceNo }}</div>
              </div>
              <div class="local-content-list">
                <span style="white-space:pre-wrap;">{{ item.showText }}</span>
                <a
                  v-if="(isRejectStatus && item.isReject) || !isRejectStatus"
                  @click="changeText(page, item, page.order)"
                  >替换</a
                >
              </div>
            </div>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </template>
  </div>
</template>

<script>
import { v1postAction } from '@/api/manage'
import { checkPermission } from './../utils/hasPermission'
export default {
  props: {
    taskDetail: {
      type: Object,
      default: () => ({})
    },
    authList: {
      type: Array,
      default: () => []
    },
    oldTextBatchStyle: {
      type: Object,
      default: () => ({})
    },
    showSeachIndex: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: '#000000'
    },
    showImgLeftListPostions: {
      type: Array,
      default: () => []
    },
    pageListData: {
      type: Array,
      default: () => []
    },
    changeAll: {
      type: Boolean,
      default: true
    },
    isRejectStatus: {
      type: Boolean,
      default: false
    },
    allHeight: {
      type: Boolean,
      default: false
    },
    bottomHeight: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      customStyle: 'background: #fff;border-radius: 4px;margin-bottom: 0px;border: 0;overflow: hidden',
      visible: false,
      selectTextId: null,
      selectTextChangeId: null,
      searchType: 'search',
      buttonType: 0,
      text: '',
      type: 1,
      searchList: [],
      textDefaultKey: [],
      textChangeDefaultKey: [],
      changeList: [],
      searchValue: '',
      changeValue: ''
    }
  },
  computed: {
    backCss() {
      return {
        '--theme-color': this.color
      }
    }
  },
  watch: {
    searchType() {
      this.text = ''
      this.type = 1
      this.searchList = []
      this.textDefaultKey = []
      this.textChangeDefaultKey = []
      this.changeList = []
      this.searchValue = ''
      this.changeValue = ''
      this.selectTextId = null
      this.selectTextChangeId = null
    }
  },
  methods: {
    textDefaultKeyChange() {
      if (!this.textDefaultKey.length) {
        this.$emit('textDefaultKeyChange')
      }
    },
    checkPermission(permission) {
      return checkPermission(permission, this.authList) && this.changeAll
    },
    openChangeList() {
      this.textChangeDefaultKey = this.changeList.map(page => page.pageId + '')
    },
    closeChangeList() {
      this.textChangeDefaultKey = []
    },
    openList() {
      this.textDefaultKey = []
      this.searchList.forEach(c => {
        c.chapter.forEach((page, pageIdx) => {
          this.textDefaultKey.push(page.pageId + '-' + c.chapterId)
        })
      })
      console.log(this.textDefaultKey)
    },
    closeList() {
      this.textDefaultKey = []
    },
    changeText(page, item, pageIdx) {
      if (!this.changeValue) {
        this.$message.error('替换文本不能为空')
        return
      }
      let indices = []
      let startIndex = 0
      while ((startIndex = item.showText.indexOf(this.searchValue, startIndex)) !== -1) {
        indices.push(startIndex)
        startIndex += this.searchValue.length
      }
      indices.forEach((i, index) => {
        for (let n = 0; n < this.searchValue.length; n++) {
          item.characterSentences.splice(i + (this.changeValue.length - this.searchValue.length) * index, 1)
        }
        this.changeValue.split('').forEach((t, idx) => {
          item.characterSentences.splice(i + idx + (this.changeValue.length - this.searchValue.length) * index, 0, {
            ...this.oldTextBatchStyle,
            characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
            characterText: t
          })
        })
      })
      item.showTexts = []
      let pArr = []
      let brIds = []
      item.characterSentences?.forEach((t, pushTtextIndex) => {
        if (t.characterText != '<br>' && t.characterText != '\n') {
          pArr.push(t)
        } else {
          brIds.push(t.characterSentenceId)
          if (pArr.length > 1) {
            /* pArr = pArr.filter(pt => pt.characterText != ' ') */
          }
          if (!pArr.length) {
            pArr.push({
              ...this.oldTextBatchStyle,
              textAlign: this.textBatchStyle.textAlign,
              fontFamily: this.textBatchStyle.fontFamily,
              characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
              characterText: ' '
            })
          }
          item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
          pArr = []
        }
        if (pushTtextIndex == item.characterSentences.length - 1 && pArr.length) {
          item.showTexts.push({ pId: '', texts: pArr, pushTtextIndex })
        }
      })
      if (!item.showTexts.length)
        item.showTexts.push({
          pId: '',
          texts: [
            {
              ...this.oldTextBatchStyle,
              textAlign: this.textBatchStyle.textAlign,
              fontFamily: this.textBatchStyle.fontFamily,
              characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
              characterText: ' '
            }
          ],
          pushTtextIndex: 0
        })
      brIds.forEach((brId, brPushIdx) => {
        if (item.showTexts[brPushIdx + 1]) {
          item.showTexts[brPushIdx + 1].pId = brId + '-' + Math.round(Math.random() * 10000) + new Date().getTime()
        }
      })
      item.showTexts[0].pId = 'newP' + Math.round(Math.random() * 10000) + new Date().getTime()
      item.showText = item.characterSentences
        .map(t => t.characterText)
        .join('')
        .replaceAll('<br>', '\n')
      this.selectTextChangeId = item.id
      this.$emit('changeSelectList', page.pageId + '-' + item.id + '-left')
      let num = this.showImgLeftListPostions[pageIdx].width / this.pageListData[pageIdx].width - 0
      let y = (item.textAreaRegion.y * num).toFixed(0) - 0
      document.getElementsByClassName('show-img-bottom')[0].scrollTop =
        this.showImgLeftListPostions[pageIdx].top - this.showImgLeftListPostions[0].top + y - 30
      this.$emit('savePageList')
    },
    searchChange() {
      if (!this.searchValue) {
        this.$message.error('未填写搜索文本')
        return
      } else {
        this.changeList = []
        this.textChangeDefaultKey = []
        this.pageListData.forEach((page, pageIdx) => {
          page.textAreas.forEach(item => {
            if (item.showText.indexOf(this.searchValue) > -1) {
              if (this.changeList.every(c => c.pageId != page.id)) {
                this.changeList.push({
                  pageId: page.id,
                  order: pageIdx,
                  textAreas: []
                })
              }
              this.textChangeDefaultKey.push(page.id + '')
              this.changeList.forEach(c => {
                if (c.pageId == page.id) {
                  c.textAreas.push(item)
                }
              })
            }
          })
        })
      }
    },
    topCenterMouseover() {
      this.$emit('topCenterMouseover')
    },
    clear() {
      this.text = ''
      this.type = 1
      this.searchList = []
      this.textDefaultKey = []
      this.textChangeDefaultKey = []
      this.changeList = []
      this.searchValue = ''
      this.changeValue = ''
      this.selectTextId = null
      this.selectTextChangeId = null
    },
    getNextText() {
      let selectTextId = this.selectTextId
      let page = null
      let item = null
      let pageIdx = null
      const data = this.nextText(page, item, pageIdx, 0, selectTextId)
      if (data) {
        data.pageIdx = data.pageIdx - 1
        this.toText(this.pageListData[data.pageIdx], data.item, data.pageIdx + 1, this.taskDetail)
        return data
      } else {
        return null
      }
    },
    getPrevText() {
      let selectTextId = this.selectTextId
      let page = null
      let item = null
      let pageIdx = null
      const data = this.prevText(page, item, pageIdx, 0, selectTextId)

      if (data) {
        data.pageIdx = data.pageIdx - 1
        this.toText(this.pageListData[data.pageIdx], data.item, data.pageIdx + 1, this.taskDetail)
        return data
      } else {
        return null
      }
    },
    nextText(page, item, pageIdx, num, selectTextId) {
      if (
        this.searchList
          .find(c => c.chapterId == this.taskDetail.chapterId)
          .chapter.some(p => p.textAreas.some(i => i.id == selectTextId))
      ) {
        this.searchList.forEach((c, cIdx) => {
          c.chapter.forEach((p, pIdx) => {
            p.textAreas.forEach((i, itemIdx) => {
              if (i.id == selectTextId) {
                if (itemIdx < c.chapter[pIdx + num].textAreas.length - 1) {
                  if (!num) {
                    item = c.chapter[pIdx + num].textAreas[itemIdx + 1]
                  } else {
                    item = c.chapter[pIdx + num].textAreas[itemIdx]
                  }
                  page = c.chapter[pIdx + num]
                  pageIdx = p.sequenceNo
                } else {
                  let nextNum = -1
                  c.chapter.forEach((rpage, rpageIdx) => {
                    if (rpageIdx > pIdx && rpage.textAreas.length && nextNum == -1) {
                      nextNum = rpage.sequenceNo
                      item = rpage.textAreas[0]
                      page = rpage
                    }
                  })
                  num = nextNum
                  pageIdx = num
                  /* this.getNextRevision(page, item, pageIdx, num, selectRevisitionId) */
                }
              }
            })
          })
        })
      }
      if (item) {
        return { page, item, pageIdx }
      } else {
        return null
      }
    },
    prevText(page, item, pageIdx, num, selectTextId) {
      if (
        this.searchList
          .find(c => c.chapterId == this.taskDetail.chapterId)
          .chapter.some(p => p.textAreas.some(i => i.id == selectTextId))
      ) {
        this.searchList.forEach((c, cIdx) => {
          c.chapter.forEach((p, pIdx) => {
            p.textAreas.forEach((i, itemIdx) => {
              if (i.id == selectTextId) {
                if (itemIdx > 0) {
                  if (!num) {
                    item = c.chapter[pIdx - num].textAreas[itemIdx - 1]
                  } else {
                    item = c.chapter[pIdx - num].textAreas[c.chapter[pIdx - num].textAreas.length - 1]
                  }
                  page = c.chapter[pIdx - num]
                  pageIdx = p.sequenceNo
                } else {
                  let nextNum = -1
                  c.chapter.forEach((rpage, rpageIdx) => {
                    if (rpageIdx < pIdx && rpage.textAreas && rpage.textAreas.length) {
                      nextNum = rpage.sequenceNo
                      item = rpage.textAreas[rpage.textAreas.length - 1]
                      page = rpage
                    }
                  })
                  num = nextNum
                  pageIdx = num
                }
              }
            })
          })
        })
      }
      if (item) {
        return { page, item, pageIdx }
      } else {
        return null
      }
    },
    toText(page, item, pageNum, c) {
      if (c.chapterId == this.taskDetail.chapterId) {
        let pageIdx = pageNum - 1
        this.selectTextId = item.id
        this.$emit('changeSelectList', page.pageId + '-' + item.id + '-left')
        let num = this.showImgLeftListPostions[pageIdx].width / this.pageListData[pageIdx].width - 0
        let y = (item.textAreaRegion.y * num).toFixed(0) - 0
        document.getElementsByClassName('show-img-bottom')[0].scrollTop =
          this.showImgLeftListPostions[pageIdx].top - this.showImgLeftListPostions[0].top + y - 30
      } else {
        localStorage.setItem('translate_download', '')
        window.open(`/translate/${c.taskId}/${c.chapterId}/${page.pageId}/${item.id}`)
      }
    },
    async search(num) {
      if (!this.text && this.type) {
        this.$message.error('未填写搜索文本')
        return
      }
      let params = {
        productionId: this.taskDetail.productionId,
        stageId: this.taskDetail.stageId,
        sentence: this.text,
        queryType: this.type
      }
      if (!num) {
        params = {
          productionId: this.taskDetail.productionId,
          chapterId: this.taskDetail.chapterId,
          stageId: this.taskDetail.stageId,
          sentence: this.text,
          queryType: this.type
        }
      }
      const res = await v1postAction('/textarea/query_memories', params)
      if (res.code == 1) {
        this.buttonType = num
        this.searchList = []
        this.textDefaultKey = []
        let arr = []
        if (this.buttonType == 1) {
          arr = res.textAreaItems
        } else {
          arr = res.textAreaItems?.filter(item => item.chapterId == this.taskDetail.chapterId)
        }
        arr.forEach(item => {
          if (this.searchList.every(i => i.chapterId != item.chapterId)) {
            this.searchList.push({
              chapterId: item.chapterId,
              chapterOrder: item.chapterOrder,
              taskId: item.taskId,
              num: 0,
              chapter: []
            })
          }
          this.searchList.forEach(chapter => {
            if (chapter.chapterId == item.chapterId) {
              if (chapter.chapter.every(c => c.pageId != item.pageId)) {
                chapter.chapter.push({
                  pageId: item.pageId,
                  sequenceNo: item.sortOrderId,
                  textAreas: []
                })
              }
            }
            chapter.chapter.forEach((page, pageIdx) => {
              if (page.pageId == item.pageId) {
                chapter.num++
                page.textAreas.push(item)
              }
            })
          })
        })
        this.searchList.forEach(chapter => {
          chapter.chapter?.forEach(c => {
            c.textAreas = c.textAreas.sort((a, b) => a.sequenceNo - b.sequenceNo)
          })
        })
        /*         let list = []
        list = res.textAreaItems?.filter(item => item.chapterId == this.taskDetail.chapterId) */
        if (res.textAreaItems.length) {
          /* this.$emit('getSearchList', { list, type: this.type ? 'right' : 'left' }) */
        } else {
          this.$message.error('无结果')
        }
      }
    },
    open() {
      this.visible = true
    },
    handleOk() {
      this.visible = false
    },
    handleCancel() {
      this.visible = false
      this.searchList = []
      this.text = ''
      this.type = 1
      this.searchValue = ''
      this.changeValue = ''
      this.changeList = []
      this.textDefaultKey = []
      this.textChangeDefaultKey = []
      this.searchType = 'search'
    }
  }
}
</script>

<style lang="less" scoped>
.open-all-annotation-list {
  position: absolute;
  line-height: 22px;
  right: 16px;
  top: 23px;
  z-index: 1;
}
.local-content-list {
  padding: 5px;
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
  flex: 1;
  .local-content-item {
    &:hover {
      .action-span {
        display: inline-block !important;
      }
    }
    .local-content-item-top {
      height: 36px;
      display: flex;
      .local-content-item-top-left {
        width: 20px;
        height: 36px;
        display: flex;
        justify-content: center;
        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }
      .local-content-item-top-right {
        font-size: 12px;
        margin-left: 5px;
        width: 100%;
        > div {
          line-height: 18px;
        }
        .action-span {
          display: none;
          transition: all 0.3s;
          &:hover {
            color: var(--theme-color);
          }
        }
        .anticon-more {
          font-size: 16px;
        }
        .action-span-select {
          display: inline-block;
        }
        .local-content-item-top-right-user {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
    .local-content-item-bottom {
      height: 35px;
      font-size: 14px;
      line-height: 25px;
      padding-left: 25px;
      color: black;
      .ant-input {
        border: none;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  .revisition-content {
    padding-bottom: 5px;
    color: black;
    > div {
      > span {
        line-height: 20px;
      }
    }
    .ocr-show-item-action-top {
      padding-top: 5px;
      .ocr-show-item-action-radio {
        font-size: 14px;
        padding-left: 6px;
        .error-span {
          background-color: #fff;
        }
        > span {
          display: inline-block !important;
          width: 25px;
          height: 25px;
          text-align: center;
          cursor: pointer;
          &:hover {
            background-color: #fff;
          }
        }
      }
      .ocr-show-item-action-input {
        padding-top: 10px;
        > .ocr-show-item-action-input-button {
          padding-top: 10px;
          padding-bottom: 10px;
          display: flex;
          justify-content: end;
          .ant-btn {
            font-size: 12px !important;
          }
        }
      }
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
    .revisition-content-error {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:hover {
        .action-span {
          display: inline-block !important;
        }
      }
      .action-span {
        display: none;
        transition: all 0.3s;
        &:hover {
          color: var(--theme-color) !important;
        }
      }
      .anticon-more {
        font-size: 16px;
      }
      .action-span-select {
        display: inline-block;
        &:hover {
          color: var(--theme-color) !important;
        }
      }
    }
  }
  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
}
.local-content-list-num {
  width: 50px;
  display: flex;
  justify-content: center;
  padding-top: 5px;
  > div {
    background-color: black;
    margin-left: 15px;
    color: #fff;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    border-radius: 50%;
    text-align: center;
  }
}
.text-list-bottom {
  overflow-y: auto !important;
  max-height: 400px !important;
  position: relative;
  .local-content-list-show {
    line-height: 24px;
  }
}
.local-content-list-show-selected {
  background-color: #f7f7f7 !important;
}
.local-content-list-show {
  display: flex;
  cursor: pointer;
  a {
    opacity: 0;
    user-select: none;
  }
  &:hover {
    background-color: #f7f7f7 !important;
    a {
      opacity: 1;
      display: inline-block;
    }
  }
}
.text-list-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .text-list-radio {
    display: inline-block;
    background-color: #f7f7f7;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    > span {
      display: inline-block;
      padding-top: 4px;
      padding-bottom: 4px;
      transition: all 0.3s;
      font-size: 12px;
      > span {
        padding-left: 10px;
        padding-right: 10px;
      }
      cursor: pointer;
      &:hover {
        color: var(--theme-color);
      }
    }
  }
  .text-list-radio-selected {
    background-color: #fff;
    color: var(--theme-color);
    border-radius: 4px;
  }
  .text-list-radio1 {
    > span {
      border-right: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
  .text-list-radio3 {
    > span {
      border-right: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
}
.ant-btn {
  /*   padding-left:20px;
  padding-right:20px;
  background-color: rgb(245,246,248); */
}
.modal-title {
  height: 36px;
  background-color: #ebebeb;
  padding: 2px 12px 2px 12px;
  line-height: 32px;
  font-weight: 400;
}
/deep/ .translate-modal .ant-modal-header {
  padding: 0 !important;
}
/deep/ .translate-modal .ant-modal-body {
  padding: 12px 24px;
}
/deep/ .translate-modal .ant-modal-close {
  height: 36px;
}
/deep/ .translate-modal .ant-modal-close-x {
  height: 36px;
  line-height: 36px;
}
::-webkit-scrollbar-button {
  height: 0;
}
::-webkit-scrollbar {
  border-radius: 0;
  width: 8px;
}
::-webkit-scrollbar-track {
  border-radius: 0;
}
::-webkit-scrollbar-track-piece {
  border-radius: 0;
}
::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #c1c1c1;
}
</style>
